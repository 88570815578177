div, span, h1, h2, textarea {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 500;
}

body {
  margin: 0;
}

.App {
}

.operator-mode {
  background-color: #c0c0c0;
  position:fixed;
  top:0;
  width:100%;
  z-index:100;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 20px;
  color: red;
}

.error-text{
  font-size:  14px;
  text-align: center;
  line-height: 1.5em;
}



.footer-section {
  background-color: #f5f5f5;
  color: #525252;
  text-align: center;
  padding-top: 40px;
  height: 100px;
  bottom: 0;
  width: 100%;
  margin-top: 12px;
}

.section-title {
  font-size: 14px;
  font-weight: 600;
  margin: 36px 0 18px 0;
  text-align: center;
}

.map-container {
  height: 400px;
}

.marker {
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  background-color: #7da036;
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 26px;
  color: white;
}

.day-subsection-title {
  font-size: 14px;
  color: #525252;
  font-weight: 700;
  margin-bottom: 12px;
}

.day-text {
  font-size: 14px;
  color: #525252;
  font-weight: 700;
}

.first {
  margin-top: 8px;
}

.last {
  margin-bottom: 8px;
}

.day-number, .operator-contact {
  display: inline-block;
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  background-color: #7da036;
  height: 26px;
  text-align: center;
  line-height: 26px;
  color: white;
  padding: 0px 16px;
}

.marker-city {
  margin-left: 5px;
  font-size: 14px;
}

.marker-text {
  display: inline-block;
  margin: 2px;
}

.marker-map {
  border-style: solid;
  border-width: 2px;
  border-color: white;
}


.information-section {
  display: grid;
  grid-template-columns: auto auto auto auto;
  border-style: solid;
  border-color: #989898;;
  border-width: 1px;
  padding: 16px 36px;
  border-radius: 6px;
}

@media screen and (max-width: 900px) {
  .information-section {
    grid-template-columns: auto auto !important;
    font-size: 14px;
  }
}

.information-header {
  font-weight: 500;
  color: #525252;
  margin: 4px 0px;
}

.information-data {
  font-weight: 600;
  margin: 4px 0px;
}

.entry-logo {
  max-width: 250px;
  margin: 0 calc(50% - 125px);
}

.entry-form {
  margin: 0 auto;
  max-width: 500px;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 24px;
}

.name-input {
  margin: 0 auto;
  height: 16px;
  border-color: #989898;
  border-style: solid;
  border-width: 1px !important;
  border-radius: 6px;
}

.entry-page {
  background-color: #f5f5f5;
  min-height: 100vh;
}



@media screen and (min-width: 900px) {
  .intro-section, .about-section, .price-section, .terms-section {
    max-width: 780px;
    margin: 0 auto;
  }

  .day-images-section {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
  }

  .price-section {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
  }

  .terms-section {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .day-content-section {
    display: grid;
    grid-template-columns: 200px auto;
    padding: 20px 0 12px 0;
    margin: 0 auto;
    max-width: 780px;
  }

  .about-section {
    display: grid;
    grid-template-columns: 200px auto;
  }

  .route-section {
    display: grid;
    grid-template-columns: 300px auto;
    margin-bottom: 18px;
  }

  .day-image {
    width: calc(100% - 12px);
    border-radius: 6px;
    margin-bottom: 10px;
  }

  .operator-image {
    border-radius: 50%;
    width: 100%;
  }

  .operator {
    margin: 18px;
  }

  .title-section {
    display: grid;
    grid-template-columns: 120px auto auto auto;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  .gct-logo {
    width: 100%;
  }

  .nav-link.primary, .nav-link:hover {
    border: solid 2px;
    border-radius: 6px;
    border-color: #7da036;
    color: #7da036;
    margin: auto 8px;
  }

  .nav-link:active {
    border: solid 2px;
    border-radius: 6px;
    border-color: #989898;
    color: #989898;
    margin: auto 8px;
  }

  .nav-link {
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin: auto 10px;
    font-weight: 600;
  }
}

@media screen and (max-width: 900px) {

  .intro-section, .about-section {
    padding: 0 12px;
  }

  .day-images-section {
    display: grid;
    grid-template-columns: auto;
  }

  .price-section {
    display: grid;
    grid-template-columns: auto;
    padding: 0 6px;
  }

  .terms-section {
    display: grid;
    grid-template-columns: auto;
    padding: 6px 12px;
  }

  .day-content-section {
    display: grid;
    grid-template-columns: auto;
    margin-top: 24px;
  }

  .day-subsection-title, .day-number {
    display: inline-block;
  }

  .first, .last {
    margin: 0;
    line-height: 26px;
    padding-left: 5px;
  }

  .day-markers {
    margin: 12px 0px;
  }

  .day-section {
    padding: 12px 12px 24px 12px;
  }

  .about-section {
    display: grid;
    grid-template-columns: auto;
  }

  .route-section {
    display: grid;
    grid-template-columns: auto;
    margin-bottom: 18px;
  }

  .day-image {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 10px;
  }

  .operator-image {
    border-radius: 50%;
    width: 50%;
    margin: 0 auto;
  }

  .operator {
    margin: 6px;
  }

  .operator-title {
    margin: 12px 0;
    text-align: center;
  }

  .title-section {
    display: grid;
    grid-template-columns: 100%;
    padding-bottom: 24px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  .gct-logo {
    width: 200px;
    margin: 0px auto;
  }

  .nav-link {
    border: solid 2px;
    border-radius: 6px;
    border-color: #989898;
    color: #525252;
    margin: 4px 0px;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-weight: 600;
  }
}


.day-background {
  background-color: #f5f5f5;
}


.day-title, .operator-title, .terms-header {
  font-size: 24px;
}

.day-lines {
  line-height: 1.5;
  white-space: pre-wrap;
}

.hotel {
  color: #7da036;
  font-size: 12px;
  font-weight: 800;
}


.service-bordered {
  color: #7da036;
  font-size: 12px;
  font-weight: 800;
  border-style: solid;
  border-color: #7da036;;
  border-width: 2px;
  border-radius: 16px;
  display: inline-block;
  padding: 1px 10px;
  margin: 2px 5px 2px 0px;
}


.service-icon {
  margin-right: 5px;
}

.checkmark-top-container-checked {
  border-color: #7da036;
}

.checkmark-top-container {
  border-color: #989898;
}

.checkmark-top-container, .checkmark-top-container-checked {
  border-style: solid;
  border-width: 1px;
  padding: 18px;
  border-radius: 6px;
  margin-top: 12px;
}

/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  line-height: 1.5;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checked-box{
  border-color: #7da036;
}

.unchecked-box {
  border-color: #989898;
}

.comment-display {
  white-space: pre-line;
  display: grid;
  grid-template-columns: auto;
  margin: 12px 0;
}

.comment-author {
  font-weight: 600;
  margin: 12px 0 0 0;
}

/* Create a custom checkbox */
.unchecked-box, .checked-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  border-style: solid;
  border-width: 2px;
  border-radius: 3px;
  margin-top: 4px;
}

.checkmark {
  transform: scale(1.4);
  color: #7da036;
  padding-left: 1px;
  padding-bottom: 3px;
}

/* On mouse-over, add a grey background color */
.checked-box:hover, .unchecked-box:hover {
  cursor: pointer;
  background-color: #ccc;
}

.day-content-subsection {
  margin-bottom: 20px;
}

button {
  display: inline-block;
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  background-color: #7da036;
  height: 26px;
  text-align: center;
  line-height: 26px;
  color: white;
  padding: 0px 16px;
  border: none;
}


button:hover {
  background-color: #ccc;
}

button:active {
  background-color: #989898;
}

.comment-button {
  margin-top: 12px;
}


.comment-button {

}

.bordered {
  border-color: #989898;
  border-style: solid;
  border-width: 1px;
  padding: 18px;
  border-radius: 6px;
}

.title-image {
  border-radius: 6px;
  width: 100%;
  vertical-align: middle;
}

.title-parent {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.title-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
  padding: 24px;
  box-sizing: border-box;
}

h1 {
  font-style: italic;
  margin: 0px;
  color: #ffffff;
  text-shadow: 2px 2px 4px #000000;
  text-align: center;
}

h2 {
  color: #ffffff;
  text-shadow: 2px 2px 4px #000000;
  margin: 0 0 18px 0;
  text-align: center;
}

.package {
  margin: 6px;
}

.package-letter {
  color: #7da036;
  font-size: 64px;
  text-align: center;
}
.price {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.unit {
  padding-bottom: 10px;
  text-align: center;
  font-size: 14px;
  color: #525252;
}

.price-description {
  font-size: 14px;
  text-align: center;
}



.operator-description{
  line-height: 1.5;
}

a {
  text-decoration: none;
}

.operator-contact {
  margin: 6px;
}

.optional-services-comment {
  color: #525252;
}

.terms-subheader {
  color: #525252;
  font-size: 14px;
}

.terms-line {
  font-size: 14px;
  line-height: 1.5;
}


.terms-header {
  margin-top: 18px;
}

.important {
  color: rgb(202, 1, 1);
}

.loading {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  text-align: center;
  font-size: 24px;
}

.comment-area {
  width: 100%;
  border: none;
  background: transparent;
}

.tip-title {
  font-weight: 700;
  margin-bottom: 12px;
}
